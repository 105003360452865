import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { Card, Avatar, Row, Col, Popover, Tag, Typography } from 'antd';
import Centered from './Centered';

const { Text } = Typography

const PostCard = ({ post }) => {
  const { slug, feature_image, primary_author, title, excerpt, featured } = post
  const url = `/blog/${slug}/`
  const readingTime = readingTimeHelper(post)
  const cover = <img alt={title} src={feature_image} />
  const avatar = primary_author.profile_image ?
    <Avatar src={primary_author.profile_image} /> : <Avatar icon="user" />
  const authorPopover = <span>{primary_author.name}</span>

  return <>
    {/* <div className="okr-hero-blog">
      <div>
        <h1>The Ultimate Guide to OKR</h1>
        <p>Everything you need to know about Objectives & Key Results</p>
      </div>
      <div className='okr-image-blog'>
        <img src={require('../../images/okr-hero.svg')} />
      </div>
    </div> */}
    <div>
      <Link to={url}><Card hoverable cover={cover} className="post-tile">
        <Card.Meta title={title} description={excerpt} />
        {featured && <Tag className='featured-tag' color="#2db7f5">Featured</Tag>}
        <Row align-items="center" type="flex" gutter={16} className="extra">
          <Col span={12}>
            <Popover content={authorPopover} trigger="hover">{avatar}</Popover>
          </Col>
          <Col span={12} className='date'>
            <Centered>
              <Text type="secondary">{readingTime}</Text>
            </Centered>
          </Col>
        </Row>

        {/* {post.tags && <div className="post-card-tags">
            <Tags post={post} visibility="public" autolink={false} /></div>} */}
      </Card>
      </Link>
    </div>
  </>
  // const old = <Link to={url} className="post-card">
  //     <header className="post-card-header">
  //         {post.feature_image &&
  //             <div className="post-card-image" style={{
  //                 backgroundImage: `url(${post.feature_image})`,
  //             }}></div>}
  //         {post.tags && <div className="post-card-tags"> <Tags post={post} visibility="public" autolink={false} /></div>}
  //         {post.featured && <span>Featured</span>}
  //         <h2 className="post-card-title">{post.title}</h2>
  //     </header>
  //     <section className="post-card-excerpt">{post.excerpt}</section>
  //     <footer className="post-card-footer">
  //         <div className="post-card-footer-left">
  //             <div className="post-card-avatar">
  //                 {post.primary_author.profile_image ?
  //                     <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name} /> :
  //                     <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name} />
  //                 }
  //             </div>
  //             <span>{post.primary_author.name}</span>
  //         </div>
  //         <div className="post-card-footer-right">
  //             <div>{readingTime}</div>
  //         </div>
  //     </footer>
  // </Link>
}

PostCard.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    featured: PropTypes.bool,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    excerpt: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default PostCard
