import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import { enquireScreen } from 'enquire-js';
import { Navigation } from '.'
import Img from 'gatsby-image'

import Header from './Header'
import Footer from './Footer'
import '../../styles'

import config from '../../utils/siteConfig'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
  const site = data.allGhostSettings.edges[0].node
  const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
  const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
  //const logo = site.logo ? <img className="site-logo" src={site.logo} alt={site.title} /> : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />;
  const logo = <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <meta name="msvalidate.01" content="C662420E78E47B3EB725F62E8ECBD90E" />
        <body className={bodyClass} />
        <link rel="shortcut icon" href={require("../../images/favicon.jpg")} />
        {typeof window !== "undefined" && (
          <script async>
            {
              (function (s, u, m, o, j, v) {
                j = u.createElement(m);
                v = u.getElementsByTagName(m)[0];
                j.async = 1;
                j.src = o;
                j.dataset.sumoSiteId = '20fb9f43061bf1efe89fcbf88d4cf0d3728569b2ae7c683a5c9ddbc831e1af0b';
                v.parentNode.insertBefore(j, v)
              })(window, document, 'script', '//load.sumo.com/')
            }
          </script>
        )}
        {/* <!-- Meta Keywords --> */}
      <meta name="keywords" content="okr board, okr examples, okr goal setting, okr full form, okr, okr meaning, okr template, okr framework, okr software, okr book, okr planning, okrs google, okrs for developers, okrs startup, business objectives, okr objectives, okr vs kpi, key objectives example, okr software, okr pdf, objectives and key results , okr best practices, okr examples for software engineers, product manager okr examples, goals and objectives, smart objectives, smart goals, what is okr, google okr, okr setting, google objectives, work goal ideas, how to implement okr, okr tool, okr dashboard, how to set okrs, okr tools, okr goal, okr platform, okr consultant, okr workshop, okr methodology" />
      </Helmet>

      <div className="viewport">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          <header className="site-head">
            <>
              <Header
                isMobile={false}
                logo={logo}
                siteTitle={site.title}
                twitter={twitterUrl}
                facebook={facebookUrl}
                siteUrl={config.siteUrl}
                navData={site.navigation} />
            </>
          </header>

          <main className="site-main">
            {/* All the main content gets inserted here, index.js, post.js */}
            <div className="home-wrapper">
              {children}
            </div>

          </main>

        </div>

        <div className="viewport-bottom">
          {/* The footer at the very bottom of the screen */}
          <footer className="site-foot">
            <Footer
              twitter={twitterUrl}
              facebook={facebookUrl}
              siteUrl={config.siteUrl}
            />
          </footer>

        </div>
      </div>

    </>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "logo.png"}) {
                    childImageSharp {
                        fixed(width: 170, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
)

export default DefaultLayoutSettingsQuery
